import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import PrimaryButton from "../components/Button/PrimaryButton";
import SecondaryButton from "../components/Button/SecondaryButton";
import { HamburgerIcon, LogoIcon } from "../components/Icons";
import BreakpointDown from "../components/Media/BreakpointDown";
import BreakpointUp from "../components/Media/BreakpointUp";
import { ArrowLineIcon } from "../components/Icons";
import CallUs from "../components/CallUs";

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  height: 64px;
  ${BreakpointUp.xl`
    height:112px;
  `}
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
`;

const Navbar = styled.div`
  padding: 0;
  margin-left: auto;
  max-width: 1120px;
  width: 100%;
  ${BreakpointUp.xl`
    margin:0 auto;
  `}
`;

const Nav = styled.nav`
  background: #fff;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 9px 0px;
  border: 1px solid #e3e6ee;
  position: relative;
  padding: 9px;
  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    height: 40%;
    width: 100%;
    box-shadow: rgba(34, 60, 126, 0.12) 0px 11px 0px 9px;
    bottom: 6px;
    ${BreakpointDown.xl`
      display:none;
    `}
  }
  ${BreakpointDown.xl`  
      padding: 40px 0;  
      flex-direction: column-reverse;
      width: 320px;
      position: absolute;
      top: 64px;
      right: 0;
      height: calc(100vh - 64px);
      z-index: 99;
      transition: all 0.3s ease 0s;
      transform: translate3d(320px,0,0);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 15px;
      overflow-y: auto;
  `}
`;

const NavBrand = styled(Link)`
  display: flex;
  overflow: hidden;
  align-items: center;
  position: absolute;
  left: 0;
  text-align: left;
  padding: 0 0px;
  width: 180px;
  svg {
    width: 100%;
  }
  ${BreakpointDown.xl`
    width: 130px;
  `}
  @media (min-width: 1366px) {
    width: 170px;
  }
  & img {
    margin-bottom: 0;
    display: block;
  }
`;

const TopNav = styled.div`
  text-align: right;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  ${BreakpointUp.xl`    
    padding:5px 0px;
    height:50px;
    background-color:#fff; 
    margin: 16px 0;
  `}
  > a {
    margin: 0 0px;
    @media (max-width: 1200px) {
      margin-left: 15px;
    }
    .btn {
      padding: 5px 18px;
    }
  }
  & .pagebtn {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 10px;
    display: inline-block;
    padding: 0;
    & + .pagebtn {
      margin-right: 0;
    }
    .btn {
      padding: 6px 18px;
    }
    ${BreakpointDown.xl`
      display:none
    `}
  }
  & .hamburger {
    display: none;
    background: #f36224;
    padding: 2px 8px;
    margin: 0 0 0 8px;
    margin-right: 0;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin-right: 9px;
    &:hover {
      background-color: #f36224;
    }
    & svg {
      fill: #fff;
      width: 22px;
    }
    ${BreakpointDown.xl`
      display: inline-flex;
    `}
  }
`;

const NavbarNav = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${BreakpointDown.xl`
    flex-direction:column;
    justify-content:flex-start;
    margin:0;
    width:100%;
    flex: auto;
  `}
`;

const NavItem = styled.div`
  margin: 0;
  padding: 0px 10px;

  &:nth-last-child(2) {
    a:before {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
  ${BreakpointDown.xl` 
    width:100%;
  `}
  &.active {
    & .nav-link {
      color: #eb1f24;
      background-color: #fff9f9;
    }
  }
  &:first-child {
    border-left: none;
  }
`;
const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 26px;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 8px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.16px;
  position: relative;
  &:before {
    width: 1px;
    height: 12px;
    content: "";
    background: #f36224;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translate(-50%, -50%);
    ${BreakpointDown.lg`
      display:none;
    `}
  }
  &:after {
    content: "";
    width: 30px;
    height: 2px;
    background: #f36224;
    display: block;
    position: absolute;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.2s all;
  }
  @media (min-width: 1200px) {
    padding: 6px 8px;
    font-size: 15px;
  }
  @media (min-width: 1366px) {
    padding: 6px 8px;
    font-size: 15px;
  }
  @media (max-width: 1199px) {
    padding: 10px 8px;
  }
  > svg {
    fill: #b1b1b1;
    transform: rotate(0deg);
    transition: all 0.3s ease 0s;
    padding-left: 5px;
    width: 14px;
    margin-left: auto;
  }
  &.active,
  &:hover {
    color: #f36224;
    &:after {
      transform: scaleX(1);
    }
    > svg {
      fill: #eb1f24;
    }
  }
`;
const NavBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  margin-top: 140px;
  .pagebtn {
    display: block;
    padding: 0;
    border: 0;
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;
      box-shadow: none;
    }
  }
  ${BreakpointUp.xl`
    display:none;
  `}
`;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: true,
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuildingGuide: false,
      isVisibleAbout: false,
      isVisibleQuote: false,
    };
    this.toggleActive = this.toggleActive.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.body.classList.remove("menu-open");
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      event.target.parentNode.tagName !== "A" &&
      event.target.tagName !== "A"
    ) {
      this.setState({
        isVisibleCarports: false,
        isVisibleGarages: false,
        isVisibleCommercial: false,
        isVisibleBarns: false,
        isVisibleFinanceRto: false,
        isVisibleBuildingGuide: false,
        isVisibleAbout: false,
      });
    }
  };
  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu,
    });
    document.body.classList.toggle("menu-open", this.state.isMenu);
  }

  render() {
    const { isVisibleQuote } = this.state;
    const PageName = this.props.location;
    return (
      <HeaderWrapper>
        <div className="container">
          <HeaderRow display={isVisibleQuote ? "none" : "block"}>
            <Navbar>
              <TopNav>
                <NavBrand className="header-logo" to="/">
                  <LogoIcon />
                </NavBrand>
                <CallUs title="Call US" />
                <Link
                  to="https://www.rtowebpay.com/account/login.aspx?id=09080606450403090C4406040100"
                  target="_blank"
                  className="pagebtn"
                >
                  <SecondaryButton icon={<ArrowLineIcon />} text="Pay Online" />
                </Link>
                <Link to="/dealer-form" className="pagebtn">
                  <PrimaryButton
                    icon={<ArrowLineIcon />}
                    text="Partner with marcus"
                  />
                </Link>
                <button className="hamburger" onClick={this.toggleActive}>
                  <HamburgerIcon />
                </button>
              </TopNav>
              <Nav className="nav">
                <NavbarNav>
                  <NavItem>
                    <NavLink
                      to="/about-us"
                      className={`nav-link ${
                        PageName === "about-us" ? "active" : ""
                      }`}
                    >
                      Our Story
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/rto-programs"
                      className={`nav-link ${
                        PageName === "rto-programs" ? "active" : ""
                      }`}
                    >
                      Rent To Own
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/barn-bonus-plus"
                      className={`nav-link ${
                        PageName === "barn-bonus-plus" ? "active" : ""
                      }`}
                    >
                      Barn Bonus Plus
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/become-a-dealer"
                      className={`nav-link ${
                        PageName === "dealer-form" ? "active" : ""
                      }`}
                    >
                      Dealer Program
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/management-program"
                      className={`nav-link ${
                        PageName === "managment-program" ? "active" : ""
                      }`}
                    >
                      Management Program
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/blog"
                      className={`nav-link ${
                        PageName === "blog" ? "active" : ""
                      }`}
                    >
                      Knowledge Hub
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/customer-payment-options"
                      className={`nav-link ${
                        PageName === "customer-payment-options" ? "active" : ""
                      }`}
                    >
                      Customer Payment
                    </NavLink>
                  </NavItem>
                  <NavBtnWrapper>
                    <Link
                      to="https://www.rtowebpay.com/account/login.aspx?id=09080606450403090C4406040100"
                      target="_blank"
                      className="pagebtn"
                    >
                      <SecondaryButton
                        icon={<ArrowLineIcon />}
                        text="Pay Online"
                      />
                    </Link>
                    <Link to="/partner-with-marcus" className="pagebtn">
                      <PrimaryButton
                        icon={<ArrowLineIcon />}
                        text="Partner with marcus"
                      />
                    </Link>
                  </NavBtnWrapper>
                </NavbarNav>
              </Nav>
            </Navbar>
          </HeaderRow>
        </div>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
