import React from "react";
import SecondaryButton from "../Button/SecondaryButton";
import { navigate } from "gatsby";
import { ArrowLineIcon } from "../Icons";
import styled from "styled-components";
import ArroDownImg from "../../images/arrow-down.svg";
import BreakpointDown from "../../components/Media/BreakpointDown";
import encode from "../../utils/encode";

// import axios from "axios";

const FormWrapper = styled.div`
  .form-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .form-group {
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      max-width: 100%;
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7) {
        width: 100%;
        max-width: 50%;
        ${BreakpointDown.sm`
				 width:100%;
				 max-width:100%;
				`}
      }
    }
  }
  select {
    border: 0;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    background: #fff;
    padding: 20px 30px;
    width: 100%;
    appearance: none;
    position: relative;
    background: #fff url(${ArroDownImg}) no-repeat !important;
    background-position: 96% 59% !important;
    &:focus {
      border-color: #cbd1e2;
      outline: none;
    }
  }
`;

class RequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      email: "",
      mobile_no: "",
      state: "",
      zipcode: "",
      comment: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const postUrl = "/?t=" + Math.floor(Date.now() / 1000);

    const data = {
      full_name: this.state.full_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    };

    // const axiosOptions = {
    //   url: postUrl,
    //   method: "post",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   data: "Harsh",
    // };
    fetch(postUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      // axios(axiosOptions)
      .then(() => {
        navigate(form.getAttribute("action"));
      })
      .catch((error) => {
        alert(error);
        navigate("/");
      });
  }
  render() {
    return (
      <FormWrapper>
        <form
          className="form-flex"
          id="rightform"
          name="RequestForm"
          method="POST"
          action="/thank-you"
          data-netlify="true"
          netlify
          onSubmit={this.handleSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              id="full_name"
              name="full_name"
              className="form-control"
              placeholder="Full Name *"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              id="mobile_no"
              name="mobile_no"
              className="form-control"
              placeholder="Phone Number *"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
          </div>
          <div className="form-group">
            <select
              className="form-control"
              id="state"
              name="state"
              onChange={this.handleChange}
            >
              <option value="---">Select State</option>
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="California">California</option>
              <option value="Colorado">Colorado</option>
              <option value="Connecticut">Connecticut</option>
              <option value="Delaware">Delaware</option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Florida">Florida</option>
              <option value="Georgia">Georgia</option>
              <option value="Guam">Guam</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="Indiana">Indiana</option>
              <option value="Iowa">Iowa</option>
              <option value="Kansas">Kansas</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Maine">Maine</option>
              <option value="Maryland">Maryland</option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Mississippi">Mississippi</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Nevada">Nevada</option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York">New York</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Northern Marianas Islands">
                Northern Marianas Islands
              </option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oregon">Oregon</option>
              <option value="Pennsylvania">Pennsylvania</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Rhode Island">Rhode Island</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee">Tennessee</option>
              <option value="Texas">Texas</option>
              <option value="Utah">Utah</option>
              <option value="Vermont">Vermont</option>
              <option value="Virginia">Virginia</option>
              <option value="Virgin Islands">Virgin Islands</option>
              <option value="Washington">Washington</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin">Wisconsin</option>
              <option value="Wyoming">Wyoming</option>
            </select>
          </div>
          <div className="form-group">
            <input
              id="zipcode"
              name="zipcode"
              className="form-control"
              onChange={this.handleChange}
              placeholder="Zipcode *"
              pattern="[0-9]*"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Message"
              id="comment"
              name="comment"
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <div className="form-action">
              <button type="submit">
                <SecondaryButton text="Submit" icon={<ArrowLineIcon />} />
              </button>
            </div>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default RequestForm;
