import React from "react";
import styled from "styled-components";
import { CloseIcon } from "../Icons";
import RequestForm from "../RequestForm";
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  Overlay,
} from "../Section";

const Modals = styled(Modal)`
  .form-flex {
    .form-group {
      &:last-child {
        margin-bottom: 0;
        max-width: 100%;
        width: 100%;
        .form-action {
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
`;

function QuotePopup(props) {
  const { isVisible } = props;

  return (
    <>
      <Modals
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-lg modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body p-0">
              <CloseButton className="close" onClick={props.onClose}>
                <CloseIcon />
              </CloseButton>
              <ModalTitle className="modal-title">Get In Touch</ModalTitle>
              <RequestForm />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modals>
      {isVisible && <Overlay />}
    </>
  );
}

export default QuotePopup;
