import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`		
	background: #223C7E;
	border: 1px solid #223C7E;
	color:#fff;	
	box-shadow: 0px 3px 20px rgba(20,61,167,0.33);
	& .icon{
		background-color: #16327B;
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background: #223C7E;
		box-shadow:0px 10px 25px rgba(20, 61, 167, 0.33);
	}
`

const PrimaryButton = (props) => {
	const { icon, text } = props;
	return(
		<PrimaryBtn className='btn'>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</PrimaryBtn>
	)
}

export default PrimaryButton