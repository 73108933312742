import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  LogoIcon,
} from "../Icons";
import BreakpointUp from "../Media/BreakpointUp";
import BreakpointDown from "../Media/BreakpointDown";

const FooterWrapper = styled.footer`
  background-color: #223c7e;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  padding: 0 0 0;
  border-top:3px solid #F36224;
`;
const FooterTop = styled.div`
  padding: 0 0;
  margin-top: -80px;
`;
const FooterMiddle = styled.div`
  padding: 20px 0;
  margin-top: 40px;
`;

const CallInfo = styled.div`
  color: #fff;
  padding-bottom: 15px;
  padding-top: 15px;
  background: #fff;
  border: 1px solid #cbd1e2;
  border-radius: 60px 5px 60px 5px;
  ${BreakpointUp.lg`
    padding-bottom: 34px;
    padding-top: 34px;
  `}
  

`
const ListInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
  ${BreakpointDown.md`
      justify-content: space-around;

  `}
`;
const ListItem = styled.div`
  position: relative;
  padding: 20px 15px;
  border-right: 1px solid #cbd1e2;
  ${BreakpointDown.lg`
      padding: 20px 12px; 
  `}
  &:last-child {
    border-right: 0;
  }
  ${BreakpointUp.lg`
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding:20px 15px; 
  `}

  ${BreakpointUp.xl`
    -ms-flex: 0 0 auto;
    flex: 1 1 auto;
    width: auto;
    max-width: 100%;
  `}
  ${BreakpointDown.md`
    border-bottom: 1px solid #cbd1e2;
    border-right: 0;
    &:last-child {
    border-bottom: 0;
  }
  `}
`;

const ListContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  > .icon {
    width: 35px;
    height: 35px;
    ${BreakpointUp.md`
      width:50px;
      height:50px;
    `}
    & svg {
      ${BreakpointDown.md`
        width:34px;
        height:34px;
      `}
    }

    + .text {
      margin-left: 15px;
    }
  }
  > .text {
    display: block;
    small {
      color: #223c7e;
      font-size: 22px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      line-height: 30px;
      ${BreakpointDown.lg`
        font-size: 18px;
      `}
    }
  }
  & a,
  .title {
    display: block;
    color: #121316;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    ${BreakpointUp.lg`
      font-size:18px; 
      line-height: 32px; 
    `}
  }

  & .btn {
    & svg {
      width: 6px;
      fill: #eb1f24;
      vertical-align: middle;
    }
  }
`;

const MenuGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
  ${BreakpointDown.lg`
    justify-content: flex-start;
  `}
`;
const MenuItemGrid = styled.div`
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointDown.xl`
    flex: 0 0 19%;
    max-width: 19%;
  `}
  ${BreakpointDown.lg`
    flex-wrap:wrap;
    flex: 0 0 28%;
    max-width: 28%;
  `}
  ${BreakpointDown.md`
    flex: 0 0 30%;
    max-width: 30%;
  `}
    ${BreakpointDown.sm`
    flex: 0 0 50%;
    max-width: 50%;
  `}


  &:first-child {
    ${BreakpointDown.lg`
      order:3;
      width: 100%;
      flex: 0 0 160px;
      margin-right: 60px;
      max-width:160px;
      `}

    @media(max-width:1440px) {
      margin-right: 10px;
    }
    ul {
      li {
          margin-top: 15px;
          padding-left: 0;
        > svg {
          max-width: 197px;
          * {
            fill: #fff;
          }
        }
        &:before {
          display: none;
        }
      }
    }
  }

  &:last-child {
    
    ${BreakpointDown.xl`
        order:1; 
        flex:0 0 44%;
        max-width:44%;
        margin-bottom:20px;
        display:flex; 
        justify-content:space-between;
      `}
        ${BreakpointDown.md`
        order:1; 
        flex:0 0 100%;
        max-width:100%;
        margin-bottom:20px;
        display:flex;
        justify-content:space-between;
      `}

    
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    ${BreakpointDown.lg`
        order:2;
    `}
  }
  ${BreakpointDown.sm`
  ul{
    li{
    a{
      margin-bottom:20px;
      border-bottom:1px dashed rgba(255, 255, 255, 0.35);
      display:block;
      padding-bottom: 10px;

    }
    }
  }
     &:nth-child(4){
      flex:0 0 100%;
      max-width:100%;
      ul{
      display:flex;
      flex-wrap:wrap;
      margin-left:-15px;
      margin-right:-15px;
      margin-bottom:0;
        li{
         flex:0 0 50%;
         max-width:50%;
         padding-left:15px;
         padding-right:15px;
         }
        }
      }
     }
  `}
`;

const MenuList = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  ${BreakpointDown.sm`
    margin: 0 0 20px;
  `}
`;
const MenuItem = styled.li`
  margin-bottom: 0;
  padding-left: 0px;
  vertical-align: text-top;
  margin: 0;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 5px;
    width: 4px;
    height: 1px;
    background: #fff;
    vertical-align: middle;
    ${BreakpointDown.sm`
     display:none;
    `}
  }
  &:hover {
    &:before{ background:#f36224}
    a{
      color: #f36224;
    }
    }
  & a {
    padding: 3px 0;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.16px;
    display: inline-block;
    font-weight: 400;
    ${BreakpointUp.md`
      padding:10px 0;
      font-size:16px;
    `}
    
  }
`;

const SocialIcon = styled.div`
  display: flex;
  padding: 0;
  margin: 10px -8px 20px -8px;

  ${BreakpointUp.sm`
    margin: 20px -10px 20px -10px;
  `}
`;

const SocialItem = styled.div`
  list-style: none;
  margin: 0 8px;
  ${BreakpointDown.sm`
    margin: 0 6px;
  `}
  a {
    text-align: center;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    display: flex;
   ${BreakpointDown.sm`
     border: 1px solid #ffffff !important;
     display:flex !important;
     padding-bottom:6px !important;
   `}
    svg {
      fill: #fff;
      width: 20px;
      height: 20px;
      ${BreakpointDown.lg`
        width: 14px;
        height: 14px;
  `}
    }
    &:hover {
      background: #f36224;
      svg {
        fill: #fff;
      }
    }
  }
`;

const Copyright = styled.div`
  display: block;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.73;
  line-height: 24px;
`;

const BusinessTimeWrapper = styled.div`
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  & + & {
    margin-top: 20px;
    ${BreakpointDown.lg`
      margin-top:10px;
      margin-bottom:10px;
   `}
  }
`;
const BusinessTitle = styled.div`
  font-size: 16px;
`;
const BusinessTime = styled.div`
  font-size: 20px;
  line-height: 41px;
`;

const FooterBottom = styled.div`
  padding: 15px 0;
  border-top: 1px solid #ebf0ff;
  opacity: 0.3;
`;

const Footer = () => (
  <FooterWrapper>
    <FooterTop>
      <div className="container">
        <CallInfo>
          <ListInfo>
            <ListItem>
              <ListContent>
                <span className="text">
                  <small>Email Address</small>
                  <div className="title"><a href="mailto:customerservice@jagbarns.com">customerservice@jagbarns.com</a></div>
                </span>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <span className="text">
                  <small>Contact Information</small>
                  <div className="title"><a href="tel:7314834930">(731) 483-4930</a></div>
                </span>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <span className="text">
                  <small>Business Hours</small>
                  <div className="title">Monday - Friday: 8AM-8PM</div>
                </span>
              </ListContent>
            </ListItem>
          </ListInfo>
        </CallInfo>
      </div>
    </FooterTop>
    <FooterMiddle>
      <div className="container">
        <MenuGrid>
          <MenuItemGrid>
            <MenuList>
              <MenuItem>
                <LogoIcon />
                <SocialIcon>
                  <SocialItem>
                    <Link className="item-link" to="/">
                      <FacebookIcon />
                    </Link>
                  </SocialItem>
                  <SocialItem>
                    <Link className="item-link" to="/">
                      <TwitterIcon />
                    </Link>
                  </SocialItem>
                  <SocialItem>
                    <Link className="item-link" to="/">
                      <LinkedInIcon />
                    </Link>
                  </SocialItem>
                  <SocialItem>
                    <Link className="item-link" to="/">
                      <InstagramIcon />
                    </Link>
                  </SocialItem>
                </SocialIcon>
              </MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid>
            <MenuList>
              <MenuItem>
                <Link to="/about-us">Our Story</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/rto-programs">Rent To Own</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/barn-bonus-plus">Barn Bonus Plus</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/dealer-form">Dealer Program</Link>
              </MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid>
            <MenuList>
              <MenuItem>
                <Link to="/management-program">Management Program</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/customer-payment-options">Customer Payment</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/contact-us">Contact Us</Link>
              </MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid>
            <BusinessTimeWrapper>
              <BusinessTitle>Office Address</BusinessTitle>
              <BusinessTime>117 S. Main St, Medina, TN 38355</BusinessTime>
            </BusinessTimeWrapper>
            <BusinessTimeWrapper>
              <BusinessTitle>Mailing Address</BusinessTitle>
              <BusinessTime>P.O. Box 448, Medina, TN 38355</BusinessTime>
            </BusinessTimeWrapper>
          </MenuItemGrid>
        </MenuGrid>
      </div>
    </FooterMiddle>

    <div className="container">
      <FooterBottom>
        <Copyright>©{new Date().getFullYear()} Marcus Rentals</Copyright>
      </FooterBottom>
    </div>
  </FooterWrapper>
);

export default Footer;