import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const SecondaryBtn = styled(BaseButton)`
	background: #F36224;
	border: 1px solid #F36224;
	color:#fff;	
	box-shadow:0px 3px 20px rgb(243, 98, 36, 0.33); 

	& .icon{
		background-color: #DF4C0D;
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background: #F36224;
		box-shadow:0px 10px 25px rgb(243, 98, 36, 0.33);
	}
`

const SecondaryButton = (props) => {
	const { icon, text } = props;
	return(
		<SecondaryBtn className='btn'>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</SecondaryBtn>
	)
}

export default SecondaryButton