import React from 'react';
import styled, { keyframes } from 'styled-components'
import BreakpointDown from "../Media/BreakpointDown"
import BreakpointUp from "../Media/BreakpointUp"
import { CallIcon } from '../Icons';

const ListContent = styled.a`  
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  justify-content:flex-end;
  > .icon{
    display:flex;
    align-items: center;
    ${BreakpointDown.lg`
       width:auto;
       height:auto;
       background:transparent;
    `}

    & svg{
      ${BreakpointDown.md`
          width:34px;
          height:34px;
      `}
    }
    + .text{
      margin-left:15px;
    }
  }
  > .text{
      display:flex;
      text-align: left;
      line-height: 22px;
      flex-direction:column; 
      ${BreakpointDown.lg`
         display:none;
      `}

    small{
      text-transform:uppercase;
      color:#121316;
      font-size: 14px;
      font-weight:600;
      margin-bottom:2px;
    }
  }
  & span{
      display:block;
      color:#223C7E;    
      font-family: 'Poppins', sans-serif;
      font-size:16px;
      line-height: 18px;
      font-weight:600;
    ${BreakpointUp.md`
        font-size:20px; 
        line-height: 20px;
    `}
     ${BreakpointDown.lg`
        max-width:30px;
     `}
     svg{
      max-width:100%;
     }
  }

  & .btn{
    & svg{
      width:6px;
      fill:#EB1F24;
      vertical-align: middle;
    }
  }
`

// tollFree

const ListItem = styled.div`
  position: relative;
  padding:0px 15px; 

  ${BreakpointUp.xl`
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}  
`

const rotate = keyframes`
  0% {
    transform: rotate(0) skew(1deg);
  }
  10% {
    transform rotate(-16deg) skew(1deg);
	}
	20% {
	  transform rotate(16deg) skew(1deg);
	}
	30% {
	  transform rotate(-16deg) skew(1deg);
	}
	40% {
	  transform rotate(16deg) skew(1deg);
	}
	50% {
	  transform rotate(0) skew(1deg);
	}
	100% {
	  transform rotate(0) skew(1deg);
	}
`
const Rotate = styled.span`
  display: inline-block;
  animation: ${rotate} 1s infinite both ease;
  & svg {
  	fill: #fff;
  	vertical-align: middle;
  }
`

const CallUs = ({title}) => {
  return(
    <ListItem>
      <ListContent  href="tel:7314834930">
      <span className="icon"><Rotate><CallIcon /></Rotate></span>
      <span className="text">
         <small>{title}</small>
         <span>(731) 483-4930</span>
    </span>
    </ListContent>
  </ListItem> 
  )
}
export default CallUs;