import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import StickyBg from "../../images/sticky.png";
import SecondaryButton from "./SecondaryButton";
import { ArrowLineIcon } from "../Icons";
import { CallIcon, ArrowRightCircle } from "../Icons";
import Image from "../image";
import BreakpointDown from "../Media/BreakpointDown";
import QuotePopup from "../QuotePopup";

const StcikyWrapper = styled.div`
  position: fixed;
  bottom: -13px;
  right: -6px;
  background: url(${StickyBg});
  background-repeat: no-repeat;
  width: 100%;
  max-width: 531px;
  z-index: 4;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 155px;
  transition: 0.5s all ease;
  ${BreakpointDown.md`
      padding-left: 117px;
      background-size:cover;
      min-height:250px;
      bottom: -44px;
      right: -6px; 
  `}
  ${BreakpointDown.sm`
       bottom: -53px;
  `}
  &.hide-sticky {
    right: -100%;
  }
`;
const Item = styled.div`
  max-width: 267px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  text-align: center;
  margin-top: 40px;
  position: relative;
  ${BreakpointDown.md`
      max-width: 207px;
      margin-top: 3px;
  `}
  h4 {
    color: #fff;
    line-height: 36px;
    ${BreakpointDown.md`
       line-height: 26px;
       display:none;
    `}
  }
`;
const CallNow = styled.a`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #f9f9fb;
  text-shadow: 0px 3px 20px #0e141c29;
  font-weight: 600;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 414px) {
    margin-top: -1px;
  }
  @media (max-width: 359px) {
    font-size: 20px;
  }
  svg {
    max-width: 30px;
    width: 100%;
    margin-right: 10px;
    * {
      fill: #fff;
    }
  }
`;

const StickyToggle = styled.div`
  position: absolute;
  right: 7px;
  top: -49px;
  z-index: 2;
  cursor: pointer;
  cursor: pointer;
  svg {
    max-width: 100%;
  }
  ${BreakpointDown.md`
    top: -32px;
    max-width: 30px;
    right: 1px;
  `}
  @media(max-width:414px) {
    top: -35px;
    max-width: 30px;
    right: 17px;
  }
`;
const StickyToggleOpener = styled.div`
  position: fixed;
  bottom: 210px;
  right: -100%;
  z-index: 5;
  box-shadow: 0px 3px 20px rgb(243, 98, 36, 0.33);
  cursor: pointer;
  background: #f36224;
  width: 60px;
  height: 60px;
  display: block;
  padding: 10px;
  border-radius: 37px 0px 0px 37px;
  transition: 0.5s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BreakpointDown.md`
       bottom: 120px;
       width: 40px;
       height: 40px;
    `}
  &.sticky-opener {
    right: 0;
  }
  svg {
    max-width: 100%;
    transform: scaleX(-1);
  }
`;
const rotate = keyframes`
  0% {
    transform: rotate(0) skew(1deg);
  }
  10% {
    transform rotate(-16deg) skew(1deg);
	}
	20% {
	  transform rotate(16deg) skew(1deg);
	}
	30% {
	  transform rotate(-16deg) skew(1deg);
	}
	40% {
	  transform rotate(16deg) skew(1deg);
	}
	50% {
	  transform rotate(0) skew(1deg);
	}
	100% {
	  transform rotate(0) skew(1deg);
	}
`;

const Rotate = styled.span`
  display: inline-block;
  animation: ${rotate} 1s infinite ease-in-out;
  & svg {
    fill: #fff;
    vertical-align: middle;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  left: 0;
  max-width: 220px;
  width: 100%;
  bottom: -6px;
  ${BreakpointDown.md`
      position: absolute;
      left: -11px;
      max-width: 166px;
      width: 100%;
      bottom: 44px;
   `}
  @media(max-width:359px) {
    left: -11px;
    max-width: 140px;
    width: 100%;
    bottom: 44px;
  }
`;

const CtaSticky = () => {
  const [toggleSticky, settoggleSticky] = useState(false);
  const [isVisibleQuote, setisVisibleQuote] = useState(false);
  const ShowQuoteModal = () => {
    setisVisibleQuote(true);
    if (typeof window !== `undefined`) {
      document.body.classList.add("popup-open");
    }
  };

  const onClosePopup = () => {
    setisVisibleQuote(false);
    if (typeof window !== `undefined`) {
      document.body.classList.remove("popup-open");
    }
  };
  const HandleSticky = () => {
    settoggleSticky(!toggleSticky);
  };

  return (
    <>
      <StickyToggleOpener
        className={`${toggleSticky ? "sticky-opener" : ""}`}
        onClick={HandleSticky}
      >
        <ArrowRightCircle />
      </StickyToggleOpener>
      <StcikyWrapper className={`${toggleSticky ? "hide-sticky" : ""}`}>
        <ImgWrapper>
          <Image name="sticky_girl.png" alt="sticky" />
        </ImgWrapper>
        <Item>
          <StickyToggle onClick={HandleSticky}>
            <ArrowRightCircle />
          </StickyToggle>
          <div className="h4">How Can We Help You Today?</div>
          <div
            onKeyDown={ShowQuoteModal}
            onClick={ShowQuoteModal}
            role="button"
            tabIndex={0}
          >
            <SecondaryButton text="Get in Touch" icon={<ArrowLineIcon />} />
          </div>
          <CallNow href="tel:7314834930">
            <Rotate>
              <CallIcon />
            </Rotate>
            <span>(731) 483-4930</span>
          </CallNow>
        </Item>
      </StcikyWrapper>
      <QuotePopup isVisible={isVisibleQuote} onClose={onClosePopup} />
    </>
  );
};

export default CtaSticky;
